<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>자원관리</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="교재출고 요청하기">
                    - 필요한 내부교재는 [내부교재 출고요청] 버튼 클릭 후 등록해 주시기 바랍니다.<br>
                    - 필요한 외부교재는 [전자결재 - 외부교재 구입신청서]를 통해 기안해 주시기 바랍니다.<br>
                    - 교재명을 클릭하면 요청상세내용을 보실 수 있습니다.<br>
                    - 내부교재 출고요청 내용 수정 및 삭제는 ‘요청‘ 상태일 때만 가능합니다.<br>
                    - 반납요청 시 [반납요청] 버튼 클릭 후 반납하고자 하는 교재 수량을 입력해 주세요. 
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <table class="table-row table-serach">
                                <tbody>
                                    <tr>
                                        <th width="180">완료/취소일</th>
                                        <td>
                                            <input type="text" class="w-80px"> ~
                                            <input type="text" class="w-80px mr-20">
                                        </td>
                                        <th width="180">교재명</th>
                                        <td>
                                            <input type="text" class="w-80per">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="180">고객사명</th>
                                        <td>
                                            <input type="text" class="w-80per">
                                        </td>
                                        <th width="180">요청자</th>
                                        <td>
                                            <select name="" id="" class="w-40per">
                                                <option value="">팀 선택</option>
                                            </select>
                                            <select name="" id="" class="w-50per ml-10">
                                                <option value="">매니저 선택</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="btn-default float-right mt-10">검색</button>
                            <div class="clear"></div>

                            <div class="mt-40 mb-20">
                                <select class="w-100px float-left">
                                    <option value="">구분 전체</option>
                                </select>
                                <select class="w-130px float-left ml-20">
                                    <option value="">출고현황 전체</option>
                                </select>
                                <button class="btn-default float-left ml-20">엑셀 다운로드</button>
                                
                                <button class="btn-default float-right">내부교재 출고요청</button>
                                <button class="btn-default float-right mr-20">출고완료</button>
                                <button class="btn-default float-right mr-20">출고취소</button>
                                <div class="clear"></div>
                            </div>
                            
                            <table class="table-col">
                                <colgroup>
                                    <col width="40">
                                    <col width="80">
                                    <col width="120">
                                    <col width="120">
                                    <col width="80">
                                    <col width="210">
                                    <col width="90">
                                    <col width="90">
                                    <col width="160">
                                    <col width="100">
                                    <col width="110">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <label><input type="checkbox" id="" name="" value=""></label>
                                        </th>
                                        <th>No</th>
                                        <th>등록일</th>
                                        <th>출고희망일</th>
                                        <th>구분</th>
                                        <th>교재명</th>
                                        <th>수량</th>
                                        <th>유/무상</th>
                                        <th>고객사명</th>
                                        <th>출고현황</th>
                                        <th>완료/취소일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label><input type="checkbox" id="" name="" value=""></label>
                                        </td>
                                        <td>5</td>
                                        <td>2019-00-00</td>
                                        <td>2019-00-00</td>
                                        <td>내부</td>
                                        <td><span class="btn-view">교재명교재명</span></td>
                                        <td>2</td>
                                        <td>유상</td>
                                        <td>당근영어</td>
                                        <td><span class="color-1">요청</span></td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label><input type="checkbox" id="" name="" value=""></label>
                                        </td>
                                        <td>4</td>
                                        <td>2019-00-00</td>
                                        <td>2019-00-00</td>
                                        <td>외부</td>
                                        <td><span class="btn-view">교재명교재명</span></td>
                                        <td>2</td>
                                        <td>유상</td>
                                        <td>당근영어</td>
                                        <td>완료</td>
                                        <td>2019-00-00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label><input type="checkbox" id="" name="" value=""></label>
                                        </td>
                                        <td>3</td>
                                        <td>2019-00-00</td>
                                        <td>2019-00-00</td>
                                        <td>내부</td>
                                        <td>Biz Basics 1 - 2014</td>
                                        <td>2</td>
                                        <td>유상</td>
                                        <td>당근영어</td>
                                        <td>취소</td>
                                        <td>2019-00-00</td>
                                    </tr>
                                </tbody>
                            </table>
                            <CarrotPaging></CarrotPaging>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 


export default {
}
</script>

<style lang="scss" scoped>
</style>